(function($) {
    'use strict';



    // $(document).on('ready', function(){

        /**
         * Setup target element to 'float' down the screen
         * addElement( 'slug-of-class', 'target-element-selector', 'offset from page header')
         * Slug: this value will be added to the body and appended with -has-passed. E.g. 'anchor-menu' will be added as 'anchor-menu-has-passed'
         * Target selector: This should contain a . or # (jQuery selector)
         * Offset: use a negative number to pick up the element earlier (useful to create some padding between the header and the element) use a positive number to pick it up later (to overlap or hide behind the header)
         */
        // if (window.cnOnScroll) {
        //     window.cnOnScroll.addElement('anchor-menu', '.c-anchor-menu', -20);
        // }
    // });
    // 
    // ADD BROWSER CLASSES
    // 
    // 
    var ieVersion = detectIE();
    var isIE =  Boolean(ieVersion);
    //console.log(isIE);

    // var isIE10plus = document.body.style.msTouchAction !== undefined;
    // if(isIE10plus){
    //     $('body').addClass("browser-ie");
    // }

    var isSafari = /constructor/i.test(window.HTMLElement);
    if(isSafari){
        $('body').addClass("browser-safari");
    }

    var isWebkit = 'WebkitAppearance' in document.documentElement.style;
    if(isWebkit && !isIE){
        $("body").addClass("browser-webkit");
    }


    // IMAGE JUMP LINKS ENABLER
    var imageSliderWidget = $(".widget_cn-core-image-slider-widget");
    if(imageSliderWidget){
        var imageSliderNav = imageSliderWidget.find(".c-slider__buttons-nav");
        imageSliderNav.on('click', 'a', function(event){
            event.preventDefault();
            $(this).parent().children('a').removeClass('is-selected');
            $(this).addClass('is-selected');
        });
 
    }
    
    // TRUNCATES TEXT IN PROPERTY LIST CTA
    var propropertyListItems = $(".c-property-list__items");
    var profitroomsPosts = $(".c-profitroom-rooms__posts");
    // To enable use of -webkit-line-clamp property for webkit browsers 


    // Line-clamp 'Polyfill' for non webkit browsers
    // Uese clamp.js by joseph ✪ schmitt
    // https://github.com/josephschmitt/Clamp.js
    if(!isWebkit || isIE){

       propropertyListItems.find(".c-cta__text p").each(function(){
            var that = $(this);
            that.succinct({
                size: 120
            });
       });    

       profitroomsPosts.find(".c-profitroom-rooms__text p").each(function(){
            var that = $(this);
            that.succinct({
                size: 200
            });
       });

    }

    // ADDS DUMMY IMAGE TO PROPERTY LIST ITEM IF NO IMAGE EXISTS
    // HELPS MAINTAIN POST SIZE
    
    profitroomsPosts.find(".c-profitroom-rooms__post").each(function(){
        var post = $(this);
        var postImage = post.find(".c-profitroom-room__image-anchor");
        //console.log(!postImage.length);
        var dummyImageElement = $("<a href='' class='c-profitroom-room__image-anchor c-profitroom-room__image-blank'></a>");

        if(!postImage.length){
            var postlink = post.find('.c-profitroom-room__more').attr('href');

            dummyImageElement.attr('href', postlink);
            post.prepend(dummyImageElement);
        }

    });


    //PARALLAX PLUGIN
    // http://pixelcog.github.io/parallax.js/
    
    if(!isIE){
        
        var parallaxLeftWindow = $('.c-cta--layout-image-left .c-cta__inner');
        var parallaxRightWindow = $('.c-cta--layout-image-right .c-cta__inner');

        parallaxLeftWindow.addClass("parallax-window").attr({
            "data-parallax" : "scroll",
            "data-image-src" : "/wp-content/themes/cn-theme-cgskilodge/dist/svg/bg-gradient-diagonal-trbl.svg"
        });
        parallaxRightWindow.addClass("parallax-window").attr({
            "data-parallax" : "scroll",
            "data-image-src" : "/wp-content/themes/cn-theme-cgskilodge/dist/svg/bg-gradient-diagonal-tlbr.svg"
        });
        parallaxLeftWindow.parallax({imageSrc: '/wp-content/themes/cn-theme-cgskilodge/dist/svg/bg-gradient-diagonal-trbl.svg'});
        parallaxRightWindow.parallax({imageSrc: '/wp-content/themes/cn-theme-cgskilodge/dist/svg/bg-gradient-diagonal-tlbr.svg'});
    }

    // USED IN CONJUCTION WTTH STICKY FOOTER ROW VARIATION
    
    var stickerFooterRow = $('.c-row--sticky-footer');
    //console.log(stickerFooterRow.length);
    if(stickerFooterRow.length){
        $("body").addClass("has-sticky-footer");
    }

    /**
    * Handle clicking #book links
    */
//    $('a[href="#book"]').on('click', function(e) {
//        //console.log('hello');       
//        e.preventDefault();
//        $('body').addClass('booking-modal-is-visible');

//    });

})(jQuery);